

















































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/includes/logic/Modules/components/ModuleConfigSettingsWrapper.vue'
import EntertainmentCallHandlerModuleBuilder
  from '@/includes/logic/Modules/modules/modules/EntertainmentCallHandlerModule/EntertainmentCallHandlerModuleBuilder'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput,
  },
})
export default class EntertainmentCallHandlerModuleView extends Mixins(InputSetups, ModuleBindings, SelectOptionsMixin) {
  @VModel() module!: EntertainmentCallHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  mounted() {
    // if (this.tariffTag) {
    //   this.$baseTemplate.saveButton.hide()
    // } else {
    //   this.$baseTemplate.saveButton.show()
    // }
  }
}
